<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-10 offset-md-1">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="row mt-2">
              <div class="col-12">
                <div class="card-title float-left">
                  <h3 class="card-label">Container Information</h3>
                </div>
                <button class="btn btn-sm btn-default float-right" v-if="!showEditContainerDiv" @click="editContainer()">
                  Edit Container
                </button>
                <button class="btn btn-sm btn-danger float-right" v-if="showEditContainerDiv" @click="cancelEdit()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div class="card-body body-fluid" v-if="!showEditContainerDiv">
            <div class="row">
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Airwaybill no</div>
                <div class="mt-2">{{ container.airwaybill }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Origin no</div>
                <div class="mt-2">{{ container.origin }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Destination</div>
                <div class="mt-2">{{ container.destination }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Authorize Receiver</div>
                <div class="mt-2">{{ container.authorizedname }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Departure Date</div>
                <div class="mt-2">{{ container.departuredate }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Arrival Date</div>
                <div class="mt-2">{{ container.arrivaldate }}</div>
              </div>

              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Flight No / Truck No no</div>
                <div class="mt-2">{{ container.flightno }}</div>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Additional Information</div>
                <div class="mt-2">{{ container.additionalinformation }}</div>
              </div>
              <div class="col-12">
                <hr>
              </div>
              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Consigne Name</div>
                <div class="mt-2">{{ consigne.name }}</div>
              </div>

              <div class="col-3 col-md-3 col-sm-6">
                <div class="font-weight-bold">Address</div>
                <div class="mt-2">{{ consigne.address }}</div>
              </div>
            </div>
          </div>
          <div class="card-body body-fluid" v-if="showEditContainerDiv">
            <div class="row">
              <div class="col-md-6">
                <label class="font-weight-bold">Airwaybill no:<span class="text-danger">*</span></label>
                <b-form-input placeholder="AWB12345" v-model="container.airwaybill"></b-form-input>
              </div>
              <div class="col-md-6">
                <label class="font-weight-bold">Type <span class="text-danger">*</span></label>
                <b-form-select :options="containerType" value-field="id" v-model="container.typeid" text-field="name"
                  size="md" class="">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select Type</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="font-weight-bold">Origin <span class="text-danger">*</span></label>
                <b-form-input placeholder="Miami" v-model="container.origin"></b-form-input>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">
                  <i class="flaticon1-arrow-down"></i></label>
              </div>
              <div class="col-md-4">
                <label class="font-weight-bold">Destination</label>
                <b-form-input placeholder="Jamaica" v-model="container.destination"></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label class="font-weight-bold">Departure Date <span class="text-danger">*</span></label>
                <b-form-input type="date" v-model="container.departuredate"></b-form-input>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Arrival Date <span class="text-danger">*</span></label>
                <b-form-input type="date" v-model="container.arrivaldate"></b-form-input>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Authorized name <span class="text-danger">*</span></label>
                <b-form-input type="text" v-model="container.authorizedname"></b-form-input>
              </div>
              <div class="col-md-3">
                <label class="font-weight-bold">Flight/Ship/Truck No.<span class="text-danger">*</span></label>
                <b-form-input type="text" placeholder="FL-2125565" v-model="container.flightno"></b-form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Select Consigne">
                  <b-form-select v-model="container.consigne_id" :options="consignes" value-field="id"
                    text-field="name"></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="font-weight-bold">Additional Information
                  <span class="text-danger">*</span></label>
                <b-form-textarea id="container-aditional-info" maxlength="256" placeholder="Type your message"
                  v-model="container.additionalinformation"></b-form-textarea>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <button type="button" class="btn btn-sm btn-primary float-right"
                    @click="updateContainer()">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="row mt-2">
              <div class="col-12">
                <div class="card-title float-left">
                  <h3 class="card-label">
                    Container Shipments Total ({{ containerShipmentsCount }})
                  </h3>
                </div>
                <div class="float-right">
                  <b-dropdown class="mr-2" right no-carret>
                    <template #button-content>
                      <span><i class="flaticon-list-3"></i>Update Options</span>
                    </template>
                    <b-dropdown-item @click="showUpdateStatusModal()"><span class="text-dark"><i
                          class="flaticon2-tag text-dark"></i>&nbsp;Update Status</span></b-dropdown-item>
                    <b-dropdown-item @click="setMarkasPaid()"><span class="text-dark"><i
                          class="flaticon2-check-mark text-dark"></i>&nbsp;Mark as paid</span></b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown class="mr-2" right no-carret>
                    <template #button-content>
                      <span><i class="flaticon2-calendar-5"></i>Assign Options</span>
                    </template>
                    <b-dropdown-item @click="showAssignShipmenttoDriverModal"><span class="text-dark"><i
                          class="flaticon2-group"></i>&nbsp;Assign to Driver</span></b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown right no-carret>
                    <template #button-content>
                      <span><i class="flaticon-menu-2"></i>More Options</span>
                    </template>
                    <b-dropdown-item @click="printMasterLabel()"><span class="text-dark"><i
                          class="flaticon2-printer text-dark"></i>&nbsp;Print Master Label</span></b-dropdown-item>
                    <b-dropdown-item @click="printInvoice()"><span class="text-dark"><i
                          class="flaticon2-printer text-dark"></i>&nbsp;Print Invoice</span></b-dropdown-item>
                    <b-dropdown-item @click="printManifest()"><span class="text-dark"><i
                          class="flaticon2-printer text-dark"></i>&nbsp;Print Manifest</span></b-dropdown-item>
                    <b-dropdown-item @click="routeToShipments()"><span class="text-dark"><i
                          class="flaticon2-plus text-dark"></i>&nbsp;Add More</span></b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="table-responsive">
              <div>
                <b-table striped hover :items="containerShipments" :fields="fields" :select-mode="multi" responsive="sm"
                  ref="selectableTable">
                  <template #cell(tracking_no)="data">
                    <a class="a" @click="GetRecord(data.item)">{{
                      data.value
                    }}</a>
                  </template>
                  <template #cell(actions)="row">
                    <b-button @click="removeShipment(row.item.csid)" class="btn-sm ml-2" variant="danger"><span
                        class="mr-1">
                        <i class="flaticon-delete-1"></i></span></b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <b-modal id="update-shipment-status-modal" ref="modal" ok-title="Update" title="Update Shipment Status"
      @ok="updateShipmentStatus" @hidden="resetUpdateStatusModal" size="lg">
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Date & Time">
            <VueCtkDateTimePicker format="YYYY-MM-DD HH:mm:ss" v-model="statusObj.datetime" />
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Status">
            <b-form-input v-model="statusObj.status" list="statusDataList"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Location">
            <b-form-input list="locationsDataList" v-model="statusObj.location"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group label="Remarks">
            <b-form-input list="remarksDataList" v-model="statusObj.remarks"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>

    <b-modal id="assign-shipment-driver-modal" ref="modal" ok-title="Submit" title="Assign Shipment"
      @ok="assignShipmentDriver" size="lg">
      <div class="row">
        <div class="col-md-3">
          <b-form-group label="Choose Driver">
            <b-form-select v-model="assignShipmentDriverObj.driver_id">
              <b-form-select-option v-for="item in drivers" :key="item.id" :value="item.id">
                {{ item.first_name }} {{ item.last_name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Service Type">
            <b-form-select :options="serviceTypeList" value-field="name" v-model="assignShipmentDriverObj.service_type"
              text-field="name">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Service Type">
            <b-form-select :options="assignActionList" value-field="id" v-model="assignShipmentDriverObj.action"
              text-field="name">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group label="Date">
            <b-form-input type="date" v-model="assignShipmentDriverObj.assigning_date"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-modal>

    <datalist id="statusDataList">
      <option v-for="(item, index) in statusesDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    <datalist id="locationsDataList">
      <option v-for="(item, index) in locationDataList" :key="index">
        {{ item.name
        }}<span v-if="item.countrycode != null">, {{ item.countrycode }}</span>
      </option>
    </datalist>
    <datalist id="remarksDataList">
      <option v-for="(item, index) in remarksDataList" :key="index">
        {{ item.name }}
      </option>
    </datalist>
    
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<style>
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>th {
  padding: 10px;
}

#productTable .form-control {
  min-width: 100px;
}

.vweight {
  width: 141px;
}

.modal-open {
  overflow: initial;
}
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import {
  mapGetters
} from "vuex";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import anymatch from "anymatch";
export default {
  data() {
    return {
      id: this.$route.params.id,
      selected: [],
      statusesDataList: [],
      locationDataList: [],
      remarksDataList: [],
      drivers: [],
      container: {
        airwaybill: "",
        origin: "",
        destination: "",
        departuredate: new Date(),
        arrivaldate: new Date(),
        authorizedname: "",
        flightno: "",
        additionalinformation: "",
        typeid: 1,
        consigne_id: null,
        is_active: true,
      },
      consigne: null,
      consignes: [],
      statusObj: {
        id: null,
        shipment_ids: [],
        datetime: "",
        status: "",
        location: "",
        remarks: "",
      },
      assignShipmentDriverObj: {
        id: null,
        container_id: this.$route.params.id,
        shipment_ids: null,
        driver_id: null,
        service_type: "Collection",
        action: 1,
        assigning_date: new Date().toISOString().substr(0, 10)
      },
      containerShipments: [],
      containerShipmentsCount: 0,
      serviceTypeList: [
        { name: "Collection" },
        { name: "Delivery" },
        { name: "Both" },
      ],
      assignActionList: [
        { id: 1, name: "Assign" },
        { id: 2, name: "Print" },
        { id: 3, name: "Assign & Print" },
      ],
      containerType: [
        {
          id: 1,
          name: "Air",
        },
        {
          id: 2,
          name: "Ship",
        },
        {
          id: 3,
          name: "Truck",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "tracking_no",
          label: "Tracking No",
          sortable: true,
        },
        {
          key: "origin",
          label: "Origin",
          sortable: true,
        },
        {
          key: "destination",
          label: "Destination",
          sortable: true,
        },
        {
          key: "itemcount",
          label: "Items count",
          sortable: true,
        },
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return new Date(value).toLocaleString();
          },
          sortable: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      showEditContainerDiv: false,
      baseUrl: "https://dev.indoboxasia.com/"//process.env.VUE_APP_BASE_URL
    };
  },
  computed: {
    //...mapGetters(["currentUser"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Container",
      },
      {
        title: "View Details",
      },
    ]);
    // this.isLoading = true;
    this.getContainer(this.$route.params.id);
    this.getStatusDataList();
    this.getLocationsDataList();
    this.getRemarksDataList();
    this.getAllDrivers();
  },
  components: {
    Loading,
  },
  methods: {
    getContainer(id) {
      ApiService.get("container", id)
        .then(({ data }) => {
          this.container = data.container;
          this.consigne = data.consigne;
        })
        .catch(() => { });

      this.getContainerShipment(this.$route.params.id);
    },
    getLocationsDataList() {
      ApiService.get("getlocationsforshipmentstatus")
        .then(({ data }) => {
          this.locationDataList = data.locations;
        })
        .catch(() => { });
    },
    getRemarksDataList() {
      ApiService.get("remarksstatuslist")
        .then(({ data }) => {
          this.remarksDataList = data;
        })
        .catch(() => { });
    },
    getStatusDataList() {
      ApiService.get("shipmentstatuslist")
        .then(({ data }) => {
          this.statusesDataList = data;
        })
        .catch(() => { });
    },
    getContainerShipment(id) {
      ApiService.get("getcontainershipments", id)
        .then(({ data }) => {
          this.containerShipments = data;
          this.containerShipmentsCount = data.length;
          this.selected = data;
        })
        .catch(() => { });
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    routeToShipments() {
      this.$router.push({
        name: "shipments-admin",
      });
    },
    GetRecord(row) {
      this.$router.push({
        name: "view-shipment",
        params: {
          id: row.id,
        },
      });
    },
    removeShipment(id) {
      ApiService.delete("containershipment", id)
        .then(() => {
          this.getContainerShipment(this.$route.params.id);
          this.makeToastVariant(
            "success",
            "Shipment successfuly removed from the container",
            "Success"
          );
        })
        .catch(() => { });
    },
    printManifest() {
      this.$router.push({
        name: "print-manifest",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    setMarkasPaid() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.isLoading = true;
      ApiService.post("bulkmarkAsPaid", { shipment_ids: shipmentids })
        .then(() => {
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Shipments marked as paid successfully",
              "Success"
            );
            this.isLoading = false;
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          alert("Error", response);
        });
    },
    showUpdateStatusModal() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      this.$bvModal.show("update-shipment-status-modal");
    },
    updateShipmentStatus() {
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.statusObj.shipment_ids = shipmentids;
      this.isLoading = true;
      ApiService.post("bulkUpdateShipmentStatus", this.statusObj)
        .then(() => {
          this.getContainerShipment(this.$route.params.id);
          this.isLoading = false;
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Shipments status updated successfully",
              "Success"
            );
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    resetUpdateStatusModal() {
      this.statusObj.datetime = "";
      this.statusObj.status = "";
      this.statusObj.location = "";
      this.statusObj.remarks = "";
    },
    printInvoice() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected to print",
          "Error"
        );
        return;
      }
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });

      var ids = shipmentids.join(",");
      window.open(this.baseUrl + "invoice_pdf/" + ids, "_blank");
    },
    printMasterLabel() {
      var id = this.$route.params.id;
      window.open(this.baseUrl + "masterlabel/" + id, "_blank");
    },
    getAllDrivers() {
      ApiService.get("getAllDrivers")
        .then(({ data }) => {
          this.drivers = data.drivers;
          this.assignShipmentDriverObj.driver_id = data.drivers.length ? data.drivers[0].id : null;
        })
        .catch(() => {
        });
    },
    showAssignShipmenttoDriverModal() {
      if (this.selected.length == 0) {
        this.makeToastVariant(
          "error",
          "No Shipment is Selected",
          "Error"
        );
        return;
      }
      this.$bvModal.show("assign-shipment-driver-modal");
    },
    assignShipmentDriver() {
      var shipmentids = [];
      this.selected.forEach((value) => {
        shipmentids.push(value.id);
      });
      this.isLoading = true;
      if (this.assignShipmentDriverObj.action == 1) {
        this.AssignShipmentDriver(shipmentids);
      }
      if (this.assignShipmentDriverObj.action == 2) {
        this.PrintRunSheetDriver(shipmentids);
      }
      if (this.assignShipmentDriverObj.action == 3) {
        this.AssignShipmentDriver(shipmentids);
        this.PrintRunSheetDriver(shipmentids);
      }
    },
    AssignShipmentDriver(shipment_ids) {
      this.assignShipmentDriverObj.shipment_ids = shipment_ids;
      ApiService.post("assignShipmentDriver", this.assignShipmentDriverObj)
        .then(() => {
          this.isLoading = false;
          this.$nextTick(() => {
            this.makeToastVariant(
              "success",
              "Shipment assigned successfully",
              "Success"
            );
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    PrintRunSheetDriver(shipment_ids) {
      this.isLoading = true;
      ApiService.get("getshipmentsinfo", shipment_ids)
        .then(({ data }) => {
          var PrintObj = {
            shipments: data.shipments,
            serviceObj: this.assignShipmentDriverObj,
            awbNo: this.container.airwaybill
          };
          this.isLoading = false;
          this.$router.push({
            name: "runsheet",
            params: {
              obj: PrintObj
            }
          });
        })
        .catch(() => { });
    },
    editContainer() {
      this.getConsignes();
      this.showEditContainerDiv = true;
    },
    cancelEdit() {
      this.showEditContainerDiv = false;
    },
    checkErrors() {
      if (
        this.container.airwaybill &&
        this.container.origin &&
        this.container.destination &&
        this.container.typeid
      ) {
        return true;
      }
      this.errors = [];
      if (!this.container.airwaybill) {
        this.errors.push("Airwaybill is required.");
      }
      if (!this.container.origin) {
        this.errors.push("Origin is required.");
      }
      if (!this.container.destination) {
        this.errors.push("Destination is required.");
      }
      if (!this.container.typeid) {
        this.errors.push("Type is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    updateContainer() {
      ApiService.update("container", this.container.id, this.container)
        .then(() => {
          this.getContainer(this.container.id);
          this.showEditContainerDiv = false;
          this.$nextTick(() => {

            this.makeToastVariant(
              "info",
              "Container updated successfully",
              "Success"
            );
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    getConsignes() {
      ApiService.get("consignee")
        .then(({ data }) => {
          this.consignes = data;
        })
        .catch(() => { });
    }
  }
};
</script>
